import { formatDate } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';
import { ReqSpvw10251Init } from 'src/app/request/req-spvw10251-init';
import { ReqSpvw10251Search } from 'src/app/request/req-spvw10251-search';
import { DropdownListDto, PartnerDto, RspSpvw10251Init } from 'src/app/response/rsp-spvw10251-init'
import { Spvw10251Dto, RspSpvw10251Search } from 'src/app/response/rsp-spvw10251-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ErrorNotificationDialogComponent } from 'src/app/dialog/error-notification-dialog/error-notification-dialog.component';
import { RspUserInfo, UserInfoDto } from 'src/app/response/rsp-user-info';
import { ReqUserInfoSearch } from 'src/app/request/req-user-info-search';
import { RspGetStoreList, GetStoreListDto } from 'src/app/response/rsp-get-store-list';
import { ReqGetStoreList } from 'src/app/request/req-get-store-list';
import { ReqAccess } from 'src/app/request/req-access';
import { Spvw10251DetailDialogComponent } from 'src/app/dialog/spvw10251-detail-dialog/spvw10251-detail-dialog.component';
import { ME200012, MI100001, MI200003, MQ200010 } from "src/app/common/const-message-id";
import { MessageService } from 'src/app/service/message.service';
import { ReqPageDto } from 'src/app/request/req-page-dto';

class Spvw10251Constant {
  public static readonly RCDKBN_ORDER_TYPE: string = '2091';
  public static readonly RCDKBN_DISTRIBUTION_STATUS: string = '2092';
  public static readonly RCDKBN_ORDERKBN: string = '2089';
  public static readonly MAXITEMS: number = 1000;
  public static readonly DATEFORMAT: string = 'yyyy-MM-dd';
  public static readonly EMPTY_STRING: string = '';
  public static readonly MESSAGE_LOADING: string = '検索中・・・';
  public static readonly ORDERKBN_CTRL = 'orderKbn';
  public static readonly ORDER_DATE_FROM_CTRL = 'orderDateFrom';
  public static readonly ORDER_DATE_TO_CTRL = 'orderDateTo';
  public static readonly STORECD_CTRL: string = 'storeCd';
  public static readonly ORDER_SLIPNO_CTRL: string = 'orderSlipNo';
  public static readonly PARTNERCD_CTRL: string = 'partnerCd';
  public static readonly ORDER_TYPE_CTRL: string = 'orderType';
  public static readonly DISTRIBUTION_STT_CTRL: string = 'distributionStt';
  public static readonly ORDER_TYPE_JP: string = '発注方法';
  public static readonly ORDERKBN_JP: string = '発注区分';
  public static readonly ZERO = 0;
  public static readonly LIMIT_RECORD = 1001;
}

export interface AccessInfo extends ReqAccess {
  cmpnyCd?: string;
  lang?: string;
  sysModeCd?: string;
}

@Component({
  selector: "app-spvw10251",
  templateUrl: "./spvw10251.component.html",
  styleUrls: ["./spvw10251.component.css"]
})
export class Spvw10251Component implements OnInit, AfterViewInit, OnDestroy {
  public itemsShow: Spvw10251Dto[] = [];
  public items: Spvw10251Dto[] = [];
  public itemRecordCount: number = 0;
  public columnIds: string[] = ["orderSlipNo","partner","orderDate","orderKbnNm","orderType","distributionStt","orderAmt"];

  public columnDefsItems: TableColumnDef[] = [
    {columnId: "orderSlipNo", header: "伝票番号", width: 120, align: "left", editable: true},
    {columnId: "partner", header: "取引先", width: 300, align: "left"},
    {columnId: "orderDate", header: "発注日", width: 80, align: "center"},
    {columnId: "orderKbnNm", header: "発注区分", width: 90, align: "left"},
    {columnId: "orderType", header: "発注方法", width: 60, align: "left"},
    {columnId: "distributionStt", header: "配信状態", width: 80, align: "left"},
    {columnId: "orderAmt", header: "発注金額", width: 80, align: "right", numberPipe: '1.2-2'}
  ];

  public userInfo: UserInfoDto = null;
  public accessInfo: AccessInfo = {
    ...this.commonService.loginUser
  }
  public isShowKeyboard: boolean = true;
  public storeCdList: GetStoreListDto[] = [];
  public partnerCdList: PartnerDto[] = [];
  public orderKbnList: DropdownListDto[] = [];
  public orderTypeList: DropdownListDto[] = [];
  public distributionSttLs: DropdownListDto[] = [];

  public tableWidth: any;
  public isDisableSrchBtn: boolean = false;

  public searchCondition: FormGroup;
  private searchConditionSubscription: Subscription;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  @ViewChild(NumberKeypadComponent, {static: false}) numberKeypadComponent: NumberKeypadComponent;
  constructor(
    public commonService: CommonService,
    private message: MessageService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.searchCondition = this.fb.group({
      storeCd: [Spvw10251Constant.EMPTY_STRING,Validators.required],
      orderDateFrom: [this.getPreviousDay()],
      orderDateTo: [new Date()],
      orderSlipNo: [Spvw10251Constant.EMPTY_STRING],
      partnerCd: [Spvw10251Constant.EMPTY_STRING],
      orderType: [Spvw10251Constant.EMPTY_STRING],
      distributionStt: [Spvw10251Constant.EMPTY_STRING],
      orderKbn: new FormArray([])
    });
    this.calcTableWidth();
    this.initPrc();
    this.searchConditionValueChange();
  }

  ngAfterViewInit(): void {
    this.set10KeyPadTarget();
  }

  getPreviousDay(){
    const oneWeekInMilisecond = 6 * 24 * 60 * 60 * 1000;
    const miliseconds = new Date().getTime() - oneWeekInMilisecond;

    return new Date(miliseconds);
  }

  async initPrc() {
    try{
      this.commonService.openSpinner(this.commonService.pageTitle, Spvw10251Constant.MESSAGE_LOADING);
      await this.getUserInfo();
      await this.getStoreList();
      await this.getInitPrc();
      this.commonService.closeSpinner();
    } catch(err) {
      this.commonService.closeSpinner();
    }
  }

  getPageDto():ReqPageDto {
    return {
      pageNum: Spvw10251Constant.ZERO,
      dispNum: Spvw10251Constant.LIMIT_RECORD
    }
  }

  getUserInfo() {
    let request: ReqUserInfoSearch = {
      access: this.accessInfo
    }
    return new Promise<void>((resolve,reject) => {
      let subsc = this.httpBasic.getUserInfo(request).subscribe(
        result => {
          this.setResultGetUserInfo(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      )
    })
  }

  setResultGetUserInfo(response: RspUserInfo) {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length > 0) {
      this.accessInfo = {
        ...this.commonService.loginUser,
        cmpnyCd: response.rows[0].mainCmpnyCd,
        lang: response.rows[0].mainLang,
        sysModeCd: response.rows[0].sysModeCd
      }
      this.userInfo = response.rows[0];
    }
  }

  getStoreList() {
    let request: ReqGetStoreList = {
      access: this.accessInfo,
      storeCd: Spvw10251Constant.EMPTY_STRING,
      userId: Spvw10251Constant.EMPTY_STRING,
      cmpyCd: this.accessInfo.cmpnyCd,
      sysModeCd: this.accessInfo.sysModeCd,
    }

    return new Promise<void>((resolve,reject) => {
      let subsc = this.httpBasic.getStoreList(request).subscribe(
        result => {
          this.setResultGetStoreList(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      )
    })
  }

  setResultGetStoreList(response: RspGetStoreList) {
    if (this.httpBasic.handleAppError(response)) return;
    this.storeCdList = response.rows;
    this.searchCondition.get(Spvw10251Constant.STORECD_CTRL).setValue(this.userInfo.mainStoreCd);
  }

  getInitPrc() {
    let request: ReqSpvw10251Init = {
      access: this.accessInfo
    }

    return new Promise<void>((resolve,reject) => {
      let subsc = this.httpBasic.initSpvw10251Init(request).subscribe(
        result => {
          this.setResultInit(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      )
    })
  }

  setResultInit(result: RspSpvw10251Init) {
    if (this.httpBasic.handleAppError(result)) return;

    this.partnerCdList = result.partnerList;
    result?.dropDownList.forEach((item: DropdownListDto) => {
      if(item.RCDKBN === Spvw10251Constant.RCDKBN_ORDER_TYPE){
        this.orderTypeList.push(item);
        return;
      }
      if(item.RCDKBN === Spvw10251Constant.RCDKBN_DISTRIBUTION_STATUS){
        this.distributionSttLs.push(item);
        return;
      }
      this.orderKbnList.push(item);
    })

    const orderKbn = this.searchCondition.get(Spvw10251Constant.ORDERKBN_CTRL) as FormArray;
    this.orderKbnList.forEach(() => {
      orderKbn.push(new FormControl(true))
    })
  }

  calcTableWidth() {
    this.tableWidth = { "width": "100%" };
  }

  searchConditionValueChange() {
    this.searchConditionSubscription = this.searchCondition.valueChanges.subscribe(() => {
      this.updateButtonState();
    })
  }

  updateButtonState() {
    this.isDisableSrchBtn = !this.searchCondition.valid;
  }

  pageChangedItem() {
    if(this.itemsShow?.length > 0) this.pageChanged();
  }

  clearSearchResult() {
    this.items = [];
    this.itemsShow = [];
    this.itemRecordCount = 0;
  }

  clearBtn() {
    if(this.items.length > 0){
      let subcription = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200010]).subscribe((data: boolean) => {
        if(data) this.clearPrc();
        subcription.unsubscribe();
      })
      return;
    }
    this.clearPrc();
  }

  clearPrc(){
    this.searchCondition.reset({
      storeCd: this.userInfo.mainStoreCd,
      partnerCd:Spvw10251Constant.EMPTY_STRING,
      orderDateFrom: this.getPreviousDay(),
      orderDateTo: new Date(),
      productCd: Spvw10251Constant.EMPTY_STRING,
      orderSlipNo: Spvw10251Constant.EMPTY_STRING,
      storeInCharge: Spvw10251Constant.EMPTY_STRING
    })

    const orderKbnList = this.searchCondition.get(Spvw10251Constant.ORDERKBN_CTRL) as FormArray;
    orderKbnList.controls.forEach((item: FormControl) => {
      item.setValue(true)
    })
    this.matPagenatorItem._changePageSize(this.commonService.paginatorOption.pageSizeOptions[0]);
    this.set10KeyPadTarget();
    this.clearSearchResult();
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
    let id = "item-table-box";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  getDate(ctrlNm: string) {
    if(this.searchCondition.get(ctrlNm).value === null) return Spvw10251Constant.EMPTY_STRING;
    return formatDate(this.searchCondition.get(ctrlNm).value, Spvw10251Constant.DATEFORMAT, 'en_US');
  }

  getReqStatus(){
    let orderKbnLs = [];
    const orderKbn = this.searchCondition.get(Spvw10251Constant.ORDERKBN_CTRL) as FormArray;
    orderKbn.controls.forEach((item: FormControl, index: number) => {
      if(item.value){
        orderKbnLs.push(this.orderKbnList[index].DATACD)
      }
    })
    return orderKbnLs.toString();
  }

  checkIsNull(): boolean{
    const orderKbnLs = this.searchCondition.get(Spvw10251Constant.ORDERKBN_CTRL) as FormArray;
    if(orderKbnLs.controls.every((item: FormControl) => !item.value)){
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.message.message[ME200012].replace('%1', Spvw10251Constant.ORDERKBN_JP));
      this.isDisableSrchBtn = true;
      return true;
    }
    return false;
  }

  searchClickBtn() {
    if(this.checkIsNull()) return;
    this.clearSearchResult();
    this.searchPrc();
  }

  searchPrc() {
    this.commonService.openSpinner(this.commonService.pageTitle, Spvw10251Constant.MESSAGE_LOADING);
    const request: ReqSpvw10251Search = {
      access: this.accessInfo,
      orderDateFrom: this.getDate(Spvw10251Constant.ORDER_DATE_FROM_CTRL),
      orderDateTo: this.getDate(Spvw10251Constant.ORDER_DATE_TO_CTRL),
      storeCd: this.searchCondition.get(Spvw10251Constant.STORECD_CTRL).value,
      orderSlipNo: this.searchCondition.get(Spvw10251Constant.ORDER_SLIPNO_CTRL).value,
      partnerCd: this.searchCondition.get(Spvw10251Constant.PARTNERCD_CTRL).value,
      orderType: this.searchCondition.get(Spvw10251Constant.ORDER_TYPE_CTRL).value,
      distributionStt: this.searchCondition.get(Spvw10251Constant.DISTRIBUTION_STT_CTRL).value,
      orderKbn: this.getReqStatus(),
      pageDto: this.getPageDto()
    }
    const subsc = this.httpBasic.searchSpvw10251(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpvw10251Search) {
    this.commonService.closeSpinner();
    if(response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if(response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subcription = dialogRef.afterClosed().subscribe(() => {
        subcription.unsubscribe();
      });
      return;
    }

    if(response.results.length === 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001]);
      return;
    }

    for(let i = 0; i < Spvw10251Constant.MAXITEMS; i ++){
      if(i >= response.results.length) break;
      this.items.push(response.results[i])
    }

    this.itemRecordCount = this.items.length ?? 0;
    if(response.results.length > Spvw10251Constant.MAXITEMS) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,this.message.message[MI200003].replace(/%1/g,Spvw10251Constant.MAXITEMS));
    }
    this.matPagenatorItem.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
    this.pageChanged();
  }

  onClickLink(row: Spvw10251Dto) {
    this.commonService.dialog.open(Spvw10251DetailDialogComponent, {
      autoFocus: false,
      data: {
        orderSlipNo: row.orderSlipNo,
        accessInfo: this.accessInfo
      }
    });
  }

  isKeyboardLocked() {
    return this.numberKeypadComponent?.isKeyboardLocked() ?? false;
  }

  set10KeyPadTarget(maxCol: number = 13, stringMode: boolean = true) {
    this.numberKeypadComponent?.setTargetForm(this.searchCondition.get(Spvw10251Constant.ORDER_SLIPNO_CTRL) as FormControl);
    this.numberKeypadComponent?.setMaxCol(maxCol);
    if(stringMode) {
      this.numberKeypadComponent?.setStringMode();
    }
  }

  getColumn(){
    return this.isShowKeyboard ? 'col-10' : 'col-12'
  }

  ngOnDestroy() {
    if(this.searchConditionSubscription) this.searchConditionSubscription.unsubscribe();
  }
}
