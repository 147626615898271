import { formatDate } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReqSpmt10321Search } from 'src/app/request/req-spmt10321-search';
import { ReqSpmt10321lInit } from 'src/app/request/req-spmt10321-init';
import { DropdownListOfUserDto } from 'src/app/response/rsp-get-dropdown-list-of-user';
import { DropdownListDto, RspSpmt10321Init } from 'src/app/response/rsp-spmt10321-init';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Spmt10321Dto, RspSpmt10321Search } from 'src/app/response/rsp-spmt10321-search';
import { Spmt10321DetailDialogComponent } from 'src/app/dialog/spmt10321-detail-dialog/spmt10321-detail-dialog.component';
import { ReqSpmt10321Exclusion } from 'src/app/request/req-spmt10321-exclusion';
import { Spmt10321ConfirmDialogComponent } from 'src/app/dialog/spmt10321-confirm-dialog/spmt10321-confirm-dialog.component';
import { ReqAccess } from 'src/app/request/req-access';
import { Req } from 'src/app/request/req';
import { ReqGetStoreList } from 'src/app/request/req-get-store-list';
import { GetStoreListDto, RspGetStoreList } from 'src/app/response/rsp-get-store-list';
import { Rsp } from 'src/app/response/rsp';
import { ME200012, MI100001, MI200003, MQ200010 } from 'src/app/common/const-message-id';
import { MessageService } from 'src/app/service/message.service';
import { ReqPageDto } from 'src/app/request/req-page-dto';

class ItemSelect {
  check: FormControl;
  result: Spmt10321Dto;
}

class DTAConstant {
  public static readonly MAXITEMS: number = 1000;
  public static readonly CHECKSTTFLG_INIT: string = '0';
  public static readonly CHECKDELFLG_ACTIVE: string = '0';
  public static readonly DATEFORMAT: string = 'yyyy-MM-dd';
  public static readonly EMPTY_STRING: string = '';
  public static readonly STORECDCTRL: string = 'storeCd';
  public static readonly SLIPNOCTRL: string = 'slipNo';
  public static readonly REQSTATUSCTRL: string = 'reqStatus';
  public static readonly REQUESTERCD: string = 'requesterCd';
  public static readonly APPROVERCD: string = 'approverCd';
  public static readonly CREDATEFROMCTRL: string = 'creDateFrom';
  public static readonly CREDATETOCTRL: string = 'creDateTo';
  public static readonly CHECKFLGSLIPNOCTRL: string = 'checkFlgSlipNo';
  public static readonly MESSAGESEARCHING: string = '検索中・・・';
  public static readonly PAGETITLE: string = '廃棄・経費振替承認';
  public static readonly QTYERRORMESSAGEID: string = "ME200009"
  public static readonly REQ_STATUS_JP: string = '申請状態'
  public static readonly REDBLACKFLG_02: string = '02' // red
  public static readonly ZERO = 0;
  public static readonly LIMIT_RECORD = 1001;
}

interface CheckboxData extends DropdownListDto{
  isChecked?: boolean;
}

@Component({
  selector: "app-spmt10321",
  templateUrl: "./spmt10321.component.html",
  styleUrls: ["./spmt10321.component.css"],
})
export class Spmt10321Component implements OnInit, OnDestroy {

  access: ReqAccess | any = {
    ...this.commonService.loginUser,
    cmpnyCd: DTAConstant.EMPTY_STRING,
    lang: DTAConstant.EMPTY_STRING,
    storeCd: DTAConstant.EMPTY_STRING,
  };

  public itemsShow: ItemSelect[] = [];
  public items: ItemSelect[] = [];
  public allChecked: boolean = false;
  public itemRecordCount: number = 0;

  public columnId: string[] = ["checkStatusFlg","slipNo","statusNm","totalDtQty","totalOrderPriceExc","slipCreDate","requesterNm","approverNm","slipComment","approverComment"]

  public columnDefsItems: TableColumnDef[] = [
    {columnId: "checkStatusFlg", header: "選択", width: 40, align: "center",checkbox: true, rowspan: 1},
    {columnId: "slipNo", header: "伝票番号", width: 80, align: "left", editable: true, rowspan: 1},
    {columnId: "statusNm", header: "申請状態", width: 50, align: "left", rowspan: 1},
    {columnId: "totalDtQty", header: "数量合計", width: 80, align: "right", rowspan: 1, numberPipe: '1.0-0'},
    {columnId: "totalOrderPriceExc", header: "原価金額合計", width: 100, align: "right", rowspan: 1, numberPipe: '1.2-2'},
    {columnId: "slipCreDate", header: "伝票作成日", width: 80, align: "center", rowspan: 1},
    {columnId: "requesterNm", header: "申請者", width: 100, align: "left", rowspan: 1},
    {columnId: "approverNm", header: "承認者", width: 100, align: "left", rowspan: 1},
    {columnId: "slipComment", header: "申請者コメント", width: 200, align: "left", rowspan: 1},
    {columnId: "approverComment", header: "承認者コメント", width: 200, align: "left", rowspan: 1},
  ];

  public storeCdList: GetStoreListDto[] = [];
  public creatorCdList: DropdownListOfUserDto[] = [];
  public reqStatusKbnList: CheckboxData[] = [];
  public itemTableWidth: any;
  public isDisableSrchBtn: boolean = false;
  public isDisableConfirmBtn: boolean = true;
  public tableHeight: number = 400;
  private subcriptionYesNoDialog: Subscription;
  public searchCondition: FormGroup;
  public searchConditionHide: FormGroup;
  private searchConditionSubscription: Subscription;
  public pickerDateBegin: MatDatepicker<null>;
  public pickerDateEnd: MatDatepicker<null>;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;
  @ViewChild('textbslipNoTextBoxox', {static: false}) slipNoTextBox: ElementRef;
  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private message: MessageService,
    private fb: FormBuilder
    ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.searchCondition = this.fb.group({
      storeCd: [DTAConstant.EMPTY_STRING,Validators.required],
      slipNo: [DTAConstant.EMPTY_STRING],
      reqStatus: this.fb.array([]),
      requesterCd: [DTAConstant.EMPTY_STRING],
      approverCd: [DTAConstant.EMPTY_STRING],
      creDateFrom: [],
      creDateTo: [],
      checkFlgSlipNo: [true]
    });
    this.fnGetAccessInfo();
    this.calcTableWidth();
    this.searchConditionSubscription = this.searchCondition.valueChanges.subscribe(() => {
      this.isDisableSrchBtn = !this.searchCondition.valid;
    });
  }

  /**
   * getInitPrc
   */
  getInitPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, DTAConstant.MESSAGESEARCHING);
    let request: ReqSpmt10321lInit = {
      access: this.access
    }

    let subsc = this.httpBasic.initSpmt10321Init(request).subscribe(
      result => {
        this.setResultInit(result);
        subsc.unsubscribe();
      },
      error => {
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  /**
   * getStoreList
   */
  getStoreList(){
    let request: ReqGetStoreList = {
      cmpyCd: this.access.cmpnyCd,
      storeCd: DTAConstant.EMPTY_STRING,
      userId: DTAConstant.EMPTY_STRING,
      sysModeCd: this.access.sysModeCd,
      access: this.access
    }

    let subsc = this.httpBasic.getStoreList(request).subscribe(
      result => {
        this.setStoreInit(result);
        subsc.unsubscribe();
      },
      error => {
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  setStoreInit(result: RspGetStoreList){
    this.commonService.closeSpinner();
    result.rows.forEach((item: GetStoreListDto) => {
      this.storeCdList.push(item);
    })

    this.searchCondition.get(DTAConstant.STORECDCTRL).setValue(this.access.storeCd);
  }

  /**
   * setResultInit
   * @param result
   */
  setResultInit(result: RspSpmt10321Init){
    this.commonService.closeSpinner();
    const reqStatus = this.searchCondition.get('reqStatus') as FormArray;
    result.dropDownList.forEach((item: DropdownListDto) => {
      this.reqStatusKbnList.push(item);
      reqStatus.push(new FormControl(false))
    })
    if(reqStatus.length > 0){
      reqStatus.controls.forEach(control => control.setValue(true));
    }
    result.userList.forEach((item: DropdownListOfUserDto) => {
      this.creatorCdList.push(item);
    })
  }

  /**
   * clearBtn
   */
  clearBtn(){
    if(this.items.length > 0){
      this.subcriptionYesNoDialog = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200010]).subscribe(confirm => {
        if(confirm) {
          this.clearConditionSearch();
          this.clearSearchResult();
          this.subcriptionYesNoDialog.unsubscribe();
        }
      })
      return;
    }
    this.clearConditionSearch();
    this.clearSearchResult();
  }

  /**
   * clearConditionSearch
   */
  clearConditionSearch(){
    const reqStatus = this.searchCondition.get('reqStatus') as FormArray;
    this.searchCondition.reset({
      storeCd : this.access.storeCd,
      checkFlgSlipNo : true
    });
    reqStatus.controls.forEach(control => control.setValue(true));
    this.matPagenatorItem._changePageSize(this.commonService.paginatorOption.pageSizeOptions[0])
  }

  /**
   * openModalConfirm
   * @param statusConfirm
   */
  openModalConfirm(statusConfirm : string) {
    scrollTo(0, 10);
    let row: Spmt10321Dto[] = [];
    this.itemsShow.forEach((item: ItemSelect) => {
      if(item.check.value)
        row.push(item.result)
    })

    const dialogRef = this.commonService.dialog.open(Spmt10321ConfirmDialogComponent, {
      data: {
        row,
        statusConfirm
      }
    });

    let subscription = dialogRef.afterClosed().subscribe((data:any) => {
      if(data?.isClose && data?.errorMessageID != DTAConstant.QTYERRORMESSAGEID){
        this.searchBtn();
      }
      subscription.unsubscribe();
    });
  }

  /**
   * calcTableWidth
   */
  calcTableWidth() {
    this.itemTableWidth = {"width": "100%"};
  }

  /**
   * pageChangedItem
   * @param event
   * @returns
   */
  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  /**
   * clearSearchResult
   */
  clearSearchResult(){
    this.items = this.itemsShow = [];
    this.allChecked = false;
    this.itemRecordCount = 0;
    this.isDisableConfirmBtn = true;
  }

  pageChanged() {
    this.isDisableConfirmBtn = true;
    this.allChecked = false;
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.items[i].check.setValue(false);
      this.itemsShow.push(this.items[i]);
      count++;
    }
    let id = "item-table-box";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  allCheck(value: boolean) {
    this.itemsShow.forEach((rec) => {
      if(rec.result.checkStatusFlg === DTAConstant.CHECKSTTFLG_INIT && rec.result.delFlg === DTAConstant.CHECKDELFLG_ACTIVE) rec.check.setValue(value)
    });
    this.allChecked = value;
    this.confirmBtnChangeStatus();
  }

  updateAllChecked() {
    this.confirmBtnChangeStatus();
    this.isCheckedAll();
  }

  isCheckedAll(){
    this.allChecked = !this.itemsShow.some(i => i.result.checkStatusFlg === DTAConstant.CHECKSTTFLG_INIT
      && i.check.value === false
      && i.result.delFlg === DTAConstant.CHECKDELFLG_ACTIVE)
  }

  searchBtn() {
    this.commonService.openSpinner(this.commonService.pageTitle, DTAConstant.MESSAGESEARCHING);
    const reqStatus = this.getReqStatus();
    if (!reqStatus || reqStatus.length === 0) {
      this.commonService.closeSpinner();
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.message.message[ME200012].replace('%1', DTAConstant.REQ_STATUS_JP));
      this.isDisableSrchBtn = true;
      this.clearSearchResult();
      return;
    }
    this.searchPrc();
  }

  /**
   * getDate
   * @param ctrlNm
   * @returns
   */
  getDate(ctrlNm: string){
    if(this.searchCondition.get(ctrlNm).value === null) {
      return DTAConstant.EMPTY_STRING;
    }
    return formatDate(this.searchCondition.get(ctrlNm).value, DTAConstant.DATEFORMAT, 'en_US')
  }

  /**
   * getReqStatus
   * @returns
   */
  getReqStatus(){
    let reqStatusLs = [];
    const reqStatus = this.searchCondition.get(DTAConstant.REQSTATUSCTRL) as FormArray;
    reqStatus.controls.forEach((item: FormControl, index: number) => {
      if(item.value){
        reqStatusLs.push(this.reqStatusKbnList[index].DATACD)
      }
    })
    return reqStatusLs.toString();
  }

  getPageDto():ReqPageDto {
    return {
      pageNum: DTAConstant.ZERO,
      dispNum: DTAConstant.LIMIT_RECORD
    }
  }

  searchPrc() {
    const request: ReqSpmt10321Search = {
      storeCd: this.searchCondition.get(DTAConstant.STORECDCTRL).value,
      reqStatus: this.getReqStatus(),
      creDateFrom: this.getDate(DTAConstant.CREDATEFROMCTRL),
      creDateTo: this.getDate(DTAConstant.CREDATETOCTRL),
      slipNo: this.searchCondition.get(DTAConstant.SLIPNOCTRL).value,
      requesterCd: this.searchCondition.get(DTAConstant.REQUESTERCD).value,
      approverCd: this.searchCondition.get(DTAConstant.APPROVERCD).value,
      checkFlgSlipNo: this.searchCondition.get(DTAConstant.CHECKFLGSLIPNOCTRL).value,
      access: this.access,
      pageDto: this.getPageDto()
    }
    this.clearSearchResult();
    const subsc = this.httpBasic.searchSpmt10321(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpmt10321Search) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      let subscription = this.commonService.openErrorDialogWithAfterCloseDialog(this.commonService.pageTitle, response.normalError[0].errMsg).subscribe(() => {
        this.searchBtn();
        subscription.unsubscribe();
      });
      return;
    }
    if (response.results.length === 0) {
      this.commonService.closeSpinner();
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001]);
      return;
    }

    for(let i = 0; i < DTAConstant.MAXITEMS; i ++){
      if(i >= response.results.length) break;
      let row: ItemSelect = {
        check: new FormControl(false),
        result: response.results[i]
      }
      this.items.push(row)
    }

    this.itemRecordCount = this.items.length ?? 0;
    if (response.results.length > DTAConstant.MAXITEMS) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,this.message.message[MI200003].replace(/%1/g,DTAConstant.MAXITEMS));
    }
    this.matPagenatorItem.pageIndex = this.commonService.paginatorOption.pageSizeIndex;
    this.pageChanged();
  }

  onClickLink(row: ItemSelect){
    this.commonService.openSpinner(this.commonService.pageTitle, DTAConstant.MESSAGESEARCHING);
    const request: ReqSpmt10321Exclusion = {
      slipNo: row.result.slipNo,
      updDateTime: row.result.updDateTime,
      access: this.access,
      delFlg: row.result.delFlg
    }
    const subsc = this.httpBasic.checkExclusionSpmt10321(request).subscribe(
      result => {
        this.checkResultClickLink(result,row);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  /**
   * checkResultClickLink
   * @param response
   * @param row
   * @returns
   */
  checkResultClickLink(response: Rsp, row: ItemSelect){
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      let subscription = this.commonService.openErrorDialogWithAfterCloseDialog(this.commonService.pageTitle, response.normalError[0].errMsg).subscribe(() => {
        this.searchBtn();
        subscription.unsubscribe();
      });
      return;
    }
    this.openModalDetail(row.result)
  }

  /**
   * openModalDetail
   * @param row
   */
  openModalDetail(row: Spmt10321Dto) {
    scrollTo(0, 10);
    this.commonService.dialog.open(Spmt10321DetailDialogComponent, {
      disableClose: true,
      data: {
        row
      }
    });
  }

  fnGetAccessInfo() {
    let req: Req = {
      access: this.access
    };
    this.httpBasic.getUserInfo(req).subscribe((res) => {
      if (this.httpBasic.handleAppError(res)) return;
      if (res.rows.length > 0) {
        this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
        this.access.storeCd = res.rows[0].mainStoreCd;
        this.access.sysModeCd = res.rows[0].sysModeCd;
        this.access.lang = res.rows[0].mainLang;
        this.getInitPrc();
        this.getStoreList();
      }
    });
  }

  /**
   * confirmBtnChangeStatus
   */
  confirmBtnChangeStatus(){
    if(this.itemsShow.some(i => i.check.value === true)){
      this.isDisableConfirmBtn = false;
      return;
    }
    this.isDisableConfirmBtn = true
  }

  rowStyle(redBlackFlg: string, column: TableColumnDef){
    let isCheckBox = column?.checkbox ?? false;
    let isLink = column?.editable ?? false;
    if(isCheckBox) {
      return DTAConstant.EMPTY_STRING;
    }
    if(redBlackFlg == DTAConstant.REDBLACKFLG_02) {
      return 'text-color-red';
    }
    return DTAConstant.EMPTY_STRING;
  }

  ngOnDestroy() {
    if (this.searchConditionSubscription) this.searchConditionSubscription.unsubscribe();
  }

  isDisableCheckBoxAll() {
    if (this.itemsShow.length == 0 || this.itemsShow.every((i: ItemSelect) => i.check.disabled)) {
      return true;
    }
    return false;
  }
}
